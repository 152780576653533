@charset "UTF-8";
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local("Inter Semibold"), local("Inter-Semibold"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-SemiBoldItalic.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-SemiBoldItalic.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local("Inter Semibold"), local("Inter-Semibold"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-SemiBold.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-SemiBold.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: local("Inter Light"), local("Inter-Light"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-LightItalicBETA.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-LightItalicBETA.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local("Inter Bold"), local("Inter-Bold"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-BoldItalic.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-BoldItalic.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: local("Inter Thin"), local("Inter-Thin"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ThinBETA.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ThinBETA.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local("Inter Extrabold"), local("Inter-Extrabold"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ExtraBoldItalic.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ExtraBoldItalic.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local("Inter Medium"), local("Inter-Medium"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-MediumItalic.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-MediumItalic.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local("Inter Medium"), local("Inter-Medium"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-Medium.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-Medium.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Inter Extrabold"), local("Inter-Extrabold"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ExtraBold.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ExtraBold.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local("Inter Black"), local("Inter-Black"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-Black.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-Black.woff) format("woff");
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: local("Inter Extralight"), local("Inter-Extralight"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ExtraLightItalicBETA.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ExtraLightItalicBETA.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Inter Bold"), local("Inter-Bold"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-Bold.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-Bold.woff) format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Inter Regular"), local("Inter-Regular"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-Regular.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-Regular.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Inter Light"), local("Inter-Light"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-LightBETA.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-LightBETA.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local("Inter Extralight"), local("Inter-Extralight"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ExtraLightBETA.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ExtraLightBETA.woff)
      format("woff");
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: local("Inter Thin"), local("Inter-Thin"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ThinItalicBETA.woff2)
      format("woff2"),
    url(https://cdn.jotfor.ms/fonts/inter/fonts/Inter-ThinItalicBETA.woff)
      format("woff");
}
*,
:after,
:before {
  box-sizing: border-box;
}
:focus {
  outline: 0;
}
input,
select {
  outline: 0;
  margin: 0;
  text-align: left;
}
/* select {
  -moz-appearance: none;
  appearance: none;
} */
img {
  max-width: 100%;
}
/* select::-ms-expand {
  display: none;
} */
button {
  outline: 0;
}

select {
  -webkit-appearance: menulist-button;
  color: black;
}
option[value=""][disabled] {
  display: none;
}
option:hover {
  background-color: green !important;
}
option {
  color: black;
}
select:invalid {
  color: #757575;
  font-weight: 400;
}
::-ms-input-placeholder {
  color: #8894ab;
  font-weight: 300;
}
/* .form-dropdown:not(.time-dropdown):not(:required),
.form-dropdown:required:invalid {
  color: #2c3345;
  font-weight: 300;
} */
@media (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi),
  not all {
  body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
}
.form-all {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 72px auto;
  width: 100%;
}
.form-line,
[data-wrapper-react="true"] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.form-line {
  /* padding: 12px 10px; */
  margin: 12px 4px;
  border-radius: 3px;
  position: relative;
  width: 100%;
  transition: background-color 0.15s;
}
.form-line,
ul.page-section {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
ul.page-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  margin: 0;
  padding: 0 38px;
  list-style: none;
}
li.form-line-column {
  width: calc(50% - 8px);
}
.form-label {
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  word-break: break-word;
}
.form-sub-label {
  font-size: 0.75em;
  margin-top: 11px;
  display: block;
  word-break: break-word;
}
.form-sub-label:empty {
  display: none;
}
.form-label-top {
  width: 100%;
  margin-bottom: 14px;
}
.form-sub-label-container {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}
.form-sub-label-container-title {
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}
.form-sub-label-container + .form-sub-label-container {
  margin-left: 8px;
}
.form-input-wide {
  width: 100%;
}

.form-dropdown {
  /* padding: 0 1.875em 0 0.625em; */
  background-position: right 10px bottom 50%;
  background-size: 0.625em;
  background-repeat: no-repeat;
}
.form-dropdown,
.form-textbox {
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid;
  display: block;
  padding-left: 10px !important;
  min-width: 100% !important;
}
.form-textbox-title {
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid;
  display: block;
  min-width: 30px !important;
  height: 40px !important;
  background-color: #fff;
  border-color: #c3cad8;
  color: #2c3345;
  margin-right: 10px !important;
}

@media (min-width: 768px) {
  .form-textbox-title {
    min-width: 100%;
  }
}
@media (max-width: 768px) {
  .form-textbox-title {
    min-width: 100% !important;
  }
}
.form-textbox-title:focus {
  border-color: #18bd5b;
  box-shadow: 0 0 0 3px #c9d8fe;
}
.form-textbox-title:hover {
  border-color: #a9bff9;
  box-shadow: 0 0 0 2px #c9d8fe;
}
.form-dropdown,
.form-textbox {
  height: 40px !important;
}
.jf-form-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 3em;
  width: 128px;
  font-size: 1em;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid;
  cursor: pointer;
  font-weight: 500;
}
.jf-form-buttons:not(.form-pagebreak-back) {
  margin-left: 10px;
}
.form-buttons-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  margin: 0 -38px 10px;
  padding: 28px 52px;
  border-top: 1px solid;
}
.form-buttons-wrapper.form-buttons-left {
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.submit-button {
  width: 180px;
}
li[data-type="control_head"] {
  list-style: none;
}
.form-header {
  margin: 0;
  font-weight: 600;
  line-height: 1.45;
}
.header-text {
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.httal {
  text-align: left;
}
.htvam {
  margin-top: auto;
}
.htvam {
  margin-bottom: auto;
}
.form-subHeader {
  font-weight: 500;
  line-height: 1.6;
}
.form-header-group {
  border-bottom: 1px solid;
  padding: 10px;
  margin-top: 1.25em;
  margin-bottom: 0.75em;
}
div.header-large {
  margin: 0 -38px;
  padding: 2.5em 52px;
}
.header-large .form-header {
  font-size: 2em;
}
.header-large .form-subHeader {
  font-size: 1em;
}
@media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
  .form-buttons-wrapper {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}
.form-section {
  margin: 12px 4px;
}
body {
  font-family: Inter, sans-serif;
  font-size: 16px;
  background-color: #ecedf3;
  color: #2c3345;
}
.form-all {
  border-radius: 3px;
  box-shadow: 0 4px 4px rgba(87, 100, 126, 0.21);
  background-color: #fff;
  max-width: 850px;
}
.form-line {
  margin-top: 12px;
  margin-bottom: 12px;
}
.form-label {
  color: #2c3345;
}
.form-sub-label {
  color: #57647e;
}
.form-dropdown,
.form-textbox {
  background-color: #fff;
  border-color: #c3cad8;
  color: #2c3345;
}
.form-dropdown:hover,
.form-textbox:hover {
  border-color: #a9bff9;
  box-shadow: 0 0 0 2px #c9d8fe;
}
.form-dropdown:focus,
.form-textbox:focus {
  border-color: #18bd5b;
  box-shadow: 0 0 0 3px #c9d8fe;
}
.form-line-error .form-checkbox:not(:checked) + label:before,
.form-line-error .form-radio:not(:checked) + label:before {
  border-color: #f23a3c;
}
.form-line-error .form-checkbox:not(:checked):hover + label:before,
.form-line-error .form-radio:not(:checked):hover + label:before {
  border-color: #fe8e92;
  box-shadow: 0 0 0 2px #ffa5a5;
}
.form-line-error .form-checkbox:not(:checked):focus + label:before,
.form-line-error .form-radio:not(:checked):focus + label:before {
  border-color: #f23a3c;
  box-shadow: 0 0 0 3px #ffa5a5;
}
.jf-form-buttons {
  border-color: #c3cad8;
}
.form-buttons-wrapper {
  border-color: #e5e7f2;
}
.submit-button {
  background-color: #18bd5b;
  border-color: #18bd5b;
  color: #fff;
}
.submit-button:hover {
  background-color: #16aa52;
}
.form-header {
  color: #2c3345;
  word-break: break-word;
}
.form-subHeader {
  color: #57647e;
  word-break: break-word;
}
.form-header-group {
  border-color: #ededf1;
}
.header-large {
  border-color: #d7d8e1;
}
.form-spinner-button-container > :before {
  color: #57647e;
}
.form-spinner-button-container > :hover:before {
  color: #16aa52;
}
.form-textbox:not(.validate[Email]) {
  width: 100%;
}
.form-line-column:not(.fixed-width) .form-dropdown,
.form-line-column:not(.fixed-width) .form-textbox {
  width: 100% !important;
}
@media screen and (max-width: 768px) {
  .jotform-form {
    padding: 30px 0;
  }
  ul.page-section {
    padding: 0;
  }
  .form-buttons-wrapper {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 667px) {
  div.form-all {
    margin: auto;
  }
  .form-line-column {
    width: 100% !important;
  }

  li[data-type="control_fullname"] [data-wrapper-react="true"] {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  li[data-type="control_fullname"]
    [data-wrapper-react="true"]
    .form-sub-label-container {
    -ms-flex: 1 1 48%;
    flex: 1 1 48%;
  }
  .form-buttons-wrapper {
    margin: 0;
    padding: 28px 14px;
  }
  .form-section .header-large {
    margin: 0;
    padding: 28px 14px;
  }
  li.form-line:not(.form-line-column)[data-type="control_fullname"]
    .form-input-wide
    [data-wrapper-react].extended
    .form-sub-label-container:last-child,
  li[data-type="control_fullname"]
    .form-sub-label-container[data-input-type="middle"] {
    margin-left: 0;
  }
  .form-buttons-wrapper {
    width: 100%;
  }
  .form-buttons-wrapper .submit-button:only-child {
    width: 100%;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .jf-form-buttons {
    width: 72px;
  }
  .jf-form-buttons.submit-button {
    width: 100px;
  }
}
/* Injected CSS Code */

/*PREFERENCES STYLE*/
/* NEW THEME STYLE */
.form-all {
  margin-top: 72px;
  background-color: undefined;
  box-shadow: undefined;
}
.form-label {
  color: undefined;
}
.form-sub-label {
  color: undefined;
}
.form-dropdown,
.form-textbox {
  border-color: undefined;
  background-color: undefined;
  color: undefined;
}
.form-dropdown:hover,
.form-textbox:hover {
  border-color: undefined;
  box-shadow: undefined;
}
.form-dropdown:focus,
.form-textbox:focus {
  border-color: undefined;
  box-shadow: undefined;
}
.form-header {
  color: undefined;
}
.form-header-group .form-subHeader {
  color: undefined;
}
.form-header-group {
  border-color: undefined;
}
.header-large {
  color: undefined;
  border-color: undefined;
}
.submit-button {
  background-color: undefined;
  border-color: undefined;
}
.form-buttons-wrapper {
  border-color: undefined;
}

/* NEW THEME STYLE */
/*PREFERENCES STYLE*/
/*PREFERENCES STYLE*/
.form-all {
  font-family: Inter, sans-serif;
}
.form-all .form-submit-button {
  font-family: Inter, sans-serif;
}
.form-header-group {
  font-family: Inter, sans-serif;
}
.form-label {
  font-family: Inter, sans-serif;
}

.form-label.form-label-auto {
  display: block;
  float: none;
  text-align: left;
  width: 100%;
}

.form-line {
  margin-top: 12px 36px 12px 36px px;
  margin-bottom: 12px 36px 12px 36px px;
}

.form-all {
  max-width: 850px;
  width: 100%;
}

.form-all {
  font-size: 16px;
}
.form-all .form-submit-button {
  font-size: 16px;
}

.form-all {
  background-color: #fff;
  border: 1px solid transparent;
}
.form-all {
  color: #2c3345;
}
.form-label-top {
  color: #2c3345;
}

.form-textbox {
  background-color: #fff;
}
.is-invalid {
  border-color: #dc3545;
  /* padding-right: calc(1.5em + 0.75rem); */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.is-valid {
  border-color: #28a745;
  /* padding-right: calc(1.5em + 0.75rem); */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
@media (max-width: 768px) {
  .form-sub-label-container {
    margin-bottom: 10px;
  }
  .form-sub-label-container-title {
    margin-bottom: 10px;
    margin-right: 10px;
    min-width: 100% !important;
  }
  .form-line {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
    padding: 0px !important;
  }
}

.emaillapeltext{
  font-size:small;
  text-align: left;
}
.formPhoneInput #adult_phone_0{ 
  height: 40px !important;
}
.MuiSvgIcon-root { 
  cursor: pointer !important;
}