.banner-img {
  background-repeat: no-repeat;
  background-size: cover;
  height: 600px;
  background-position: center center;
}
.carousel {
  position: relative;
  min-height: 31vw !important;
}

@media (max-width: 1400px) {
  .banner-img {
    height: 450px;
  }
}
@media (max-width: 1000px) {
  .banner-img {
    height: 300px;
  }
}
@media (max-width: 700px) {
  .banner-img {
    height: 200px;
  }
}



.HomesliderBannnerCaption {
  color: rgb(255, 255, 255) !important;
  /* color: rgb(238, 228, 228) !important; */
  border: none !important;
  bottom: 25px !important;
  position: relative;
  left: 170px;
  cursor: text !important;
  margin-top: -302px !important;
  font-weight: bolder !important;
}
.HomesliderbannerHeadFont {
  font-size: 34px !important;
  font-weight: bolder !important;
}

.HomesliderbannerpassengerFont {
  font-size: 25px !important;
  font-weight: bolder !important;
}

.Homesliderbackgroundbanner {
  /* font-size: 15px !important; */
  background-color: #00000042 !important;
  font-weight: bolder !important;
}

.HomesliderBannnerCaptionMObile {
  font-size: 12px !important;
  font-weight: bolder !important;
  color: rgb(255, 255, 255) !important;
  /* color: rgb(238, 228, 228) !important; */
  border: none !important;
  /* bottom: 25px !important; */
  position: relative;
  left: 6px;
  cursor: text !important;
  margin-top: -112px !important;
}

.Homecarousel-caption {
  position: absolute;
  right: 15%;
  bottom: 99px !important;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}