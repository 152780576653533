#top-header {
    background-color: #60ac5d !important;
}

.top-header-container,
.header-container {
    width: 100%;
}

#top-header .nav-link-item {
    padding: 0 10px !important;
    border: 0 !important;
    background-color: #60ac5d !important;
    color: white !important;
}

#header {
    background-color: white !important;
}

.header-nav {
    box-shadow: 0 5px 10px #dddddd3d;
}

#header .nav-link-item {
    padding: 0 10px !important;
    border: 0 !important;
    background-color: white !important;
    color: black !important;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
}

.logo-img {
    height: 100px !important;
    margin-top: -22px;
}

.Lists {
    position: absolute;
    margin-top: 34px;
    border-top: green solid 2px;
}

.listItem:hover {
    background-color: green;
    color: white !important;
}

.search-box-container {
    display: inline;
}

@media (max-width: 768px) {
    .toolbar__navigation-items {
        display: none;
    }

    .destinationlist {
        display: none;
    }

    .topHeader {
        display: none !important;
    }
}

@media (max-width: 992px) {
    .search-box-container {
        display: none;
    }
}

/* @media (min-width: 768px) { */
@media (min-width: 992px) {
    .toolbar__toggle-button {
        display: none;
    }
}

.toolbar__toggle-button {
    right: 5px;
}

.dropdown:hover > .dropdown-menu {
    display: block;
}


/* .dropdown {
  margin-bottom: -120px !important;
} */
.dropdown2 {
    position: static !important;
}

.dropdown-menu2 {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    margin-top: -20px !important;
    width: 97% !important;
}

.dropdown-menu {
    border-top: green solid 4px !important;
}

.nav-link {
    font-weight: bold;
    margin-top: 25px !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #000 !important;
    font-weight: 500;
    padding-right: 25px !important;
}

.dropdown-toggle::after {
    display: none !important;
}
/* 
.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  margin-top: -60px;
} */

.react-autosuggest__container {
    position: relative;
    display: inline;
}

.react-autosuggest__input {
    width: 162px !important;
    padding: 10px 5px !important; /* 20.5px */
    font-family: Helvetica, sans-serif !important;
    font-weight: 300 !important;
    font-size: 16px !important;
    border: 1px solid #aaa !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}


.react-autosuggest__input {
    /* width: 140px !important; */
}

/* 
.react-autosuggest__input:focus {
  border: 1px solid #5cb85c !important;
} */

.react-autosuggest__container--open .react-autosuggest__input {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    display: block;
    position: absolute;
    /* top: 51px; */
    width: 240px !important;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 2;
    margin-right: 100px;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-align: left;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

    .react-autosuggest__suggestion:hover {
        background-color: #5cb85c;
        color: #fff;
    }

.search-button {
    background: transparent;
    border: 1px solid #aaa;
    padding: 10px !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
