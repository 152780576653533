
html {
  height: 100%;
}
#body {
  margin: 0;
  font-family: Roboto, HelveticaNeue, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #fff !important;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff !important;
}
a:hover {
  text-decoration: none !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.et-icons,
.flight-icon,
.hotel-icon,
.departure-flight-icon,
.arrival-flight-icon,
.passengers-icon,
.visa-icon {
  background: url("https://www.ethiopianholidays.com/assets/image/et-icon-collection.png") !important;
  background-repeat: no-repeat !important;
  display: inline-block !important;
  height: 35px !important;
  width: 40px !important;
  left: -10px !important;
  position: relative !important;
  top: 8px !important;
}
.page-item.active .page-link {
  background-color: #099209 !important;
  border-color: #a4cca4 !important;
  color: white !important;
}
.page-link {
  color: black !important;
}
.page-link:hover {
  color: green !important;
}
.page-item.disabled .page-link {
  color: #6c757d !important;
}
.react-pdf__Page{
  margin: auto !important;
  width: 60% !important;
  padding: 10px !important;
  text-align: center !important;
}
.react-pdf__message--error{
  text-align: center;
  margin-top: 20%;
  color: red;
  font-size: 30px;
}