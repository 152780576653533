.special-offer {
    max-width: 1400px;
    margin: 100px auto;
}

.special-offer-head {
    margin-bottom: 10px;
    font-family: none;
    font-size: 50px;
}

.card-img {
    height: 200px !important;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}

.et-btn {
    background-color: #60ac5d !important;
    color: white !important;
}

.destination_tab .nav-item.nav-link.active {
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    margin-right: 2px;
    cursor: pointer;
    color: #2E7D32 !important;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.destination_tab .nav-item.nav-link {
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    margin-right: 2px;
    cursor: pointer;
    color: #fff !important;
    background-color: #2E7D32;
    border-color: #dee2e6 #dee2e6 #fff;
}

.destination_tab .nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.destination_tab .nav-link {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 15px;
}

.destination_tab .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.card__wrap--outer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

    .card__wrap--outer .card__wrap--inner {
        display: flex;
        flex-direction: row;
        width: 33.33%;
    }

        .card__wrap--outer .card__wrap--inner .card {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
