button.slick-arrow {
  background-color: black !important;
  height: 66px !important;
  width: 67px !important;
  border-radius: 58px !important;
  opacity: 65% !important;
  /* position: absolute !important; */
  /* display: inline  !important; */
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.roundedHalf {
  border-top-left-radius: 25px;
  border-bottom-right-radius: 25px;
  /* border-radius:25px !important; */
}

.special-offer-head-campaign {
  /* margin-bottom: 60px; */
  font-family: Roboto, sans-serif !important;
  font-size: 20px !important;
  font-weight: bolder;
}

/* ////////wedjetr */

/* 

  .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: max-content;
    outline: none;
}

@media (max-width: 991px){
.autocomplete-wrapper input {
    width: auto;
}} */

.DateInput {
  margin: 0;
  /* padding: 2; */
  background: #fff;
  position: relative;
  display: inline-block;
  /* width:-webkit-fill-available; */
  vertical-align: middle;
}
.DateInput_input {
  /* width: 100% !important; ; */
  /* padding: 50px*/
  /* padding-left: 3px !important; */
  /* padding-right: 3px !important; */
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
  /* width: 100%; */
}

.formgroupDate {
  margin-bottom: 1rem;
  width: 100% !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}

.autocomplete-wrappernationality input {
  border: 1px solid #cecece;
  padding: 7px 15px;
  font-size: 14px;
  min-width: 100% !important;
}

/* //////////wedjetr */

.nav-tabs {
  border-bottom: 0px !important;
  padding-top: 0px !important;
}
.css-xuedat-control {
  border-color: #4caf50 !important;
  box-shadow: none !important;
  outline: none !important;
}
#build-trip-icon {
  transform: rotate(55deg) !important;
  font-size: 30px !important;
  top: 8px !important;
}
body {
  overflow: scroll !important;
  background: #cbcbd221 !important;
  font-family: Roboto, HelveticaNeue, Arial, sans-serif;
}
.flight-icon {
  background-position: -47px 6px !important;
}

.hotel-icon {
  background-position: -426px -123px !important;
  top: 14px !important;
  left: 0 !important;
  display: inline-block;
  position: relative;
  width: 35px !important;
}
.departure-flight-icon {
  background-position: -42px -4px !important;
}
.arrival-flight-icon {
  background-position: -44px -51px !important;
}
.input-group-text {
  background-color: #fff;
}
.passengers-icon {
  background-position: -43px -277px !important;
}
.visa-icon {
  background-position: -370px -48px !important;
  top: 14px !important;
  left: 0 !important;
  display: inline-block;
  position: relative;
  width: 35px !important;
}
.parent.active .visa-icon {
  background-position: -400px -49px !important;
}
.visa-icon-input {
  background-position: -364px -48px !important;
}
nav.nav-tabs {
  background: #fff;
  border-top: 0;
  position: relative;
  z-index: 100;
}
.booking-option-tab.parent.nav-item.nav-link {
  background: #80808038;
  color: #000 !important;
  margin-top: 0 !important;
  margin-bottom: 1rem;
}
.parent.active {
  border-bottom: 3px solid #60ac5d !important;
  color: #000 !important;
  background: #fff !important;
}
.parent.active span {
  color: #60ac5d !important;
}
.parent.active .flight-icon {
  background-position: -148px 7px !important;
}
.parent.active .hotel-icon {
  background-position: -471px -123px !important;
}
.material-icons {
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga";
}
.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}
#tour-package-icon {
  padding: 0 10px;
  position: relative;
  top: 5px;
  font-size: 25px;
}
.booking-option-tab {
  color: #72728c !important;
  text-align: center;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.journey-type-option {
  font-size: 16px !important;
  margin: 5px !important;
  cursor: pointer !important;
  color: #495057 !important;
  background-color: #fff !important;
}
.journey-type-option.active {
  padding-bottom: 5px !important;
  border-bottom: 2px solid #60ac5d !important;
}

.search-btn {
  font-size: 20px !important;
  font-family: sans-serif;
  float: right;
  height: 45px !important;
  top: 0;
  border: none !important;
  border-radius: 2px;
  padding: 0 60px !important;
  background-color: #5cb85c !important;
}
.btn-plus {
  color: #60ac5d !important;
}
.trip {
  /* height: 50px !important; */
  background: #fff !important;
  outline: 0 !important;
  font-size: 14px !important;
  color: #72728c !important;
  font-family: "Roboto Slab", serif !important;
  width: 100%;
}
.select-widget {
  height: 50px !important;
  background: #fff !important;
  outline: 0 !important;
  font-size: 20px !important;
  color: #60ac5d !important;
  font-family: "Roboto Slab", serif !important;
  width: 300 !important;
  -webkit-appearance: none;
}
.numberOfGuestContainer {
  padding: 10px;
  box-shadow: 0 5px 10px #ccc;
}
.numberOfGuestContainer2 {
  padding: 10px;
  margin-top: -30px;
  box-shadow: 0 5px 10px #ccc;
  max-width: 400px !important;
}
.num-guest-container {
  display: flex;
}
.closing-button {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50% !important;
}
.centerWidget {
  width: 150px;
  margin: 40px auto;
}

.RoomLabel {
  float: left;
}
.top-circle {
  box-shadow: none;
  border: 0;
  display: inline;
}
.top-circle > .btn {
  border: 1px solid #dddd;
  width: 50px;
  height: 50px;
}
.inside-icon-minus {
  color: red;
  max-width: 20px;
  max-height: 20px;
}

.inside-icon-plus {
  /* background-color: white; */
  color: #60ac5d;
  max-width: 20px;
  max-height: 20px;
}
.comboBox {
  border: 1px solid #d1d1d163;
  margin: 0 !important;
  color: #4caf50 !important;
  font-weight: 500;
  font-family: "Roboto Slab", serif !important;
  box-shadow: 0 5px 10px #d1d1d163;
  max-width: 100% !important;
  max-height: 61.5px !important;
  position: relative;
}
/* input::-webkit-calendar-picker-indicator {
  display: none;
} */
.addFlight {
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
}
.addFligtDiv {
  float: left;
}
.minusFlightDiv {
  float: right;
}
.minusFlight {
  background-color: red;
  color: white;
  border-radius: 50%;
}
.autocomplete-wrapper {
  position: relative;
  display: flex;
  flex-grow: 1;
}

.autocomplete-wrapper > div {
  width: 100%;
}
/* .DateInput > div {
  width: 100%;
}
.DateInput input {
  width: 100% !important;
} */
.autocomplete-wrapper input {
  border: 1px solid #cecece;
  padding: 12px 15px;
  font-size: 14px;
  min-width: 100% !important;
}
input#start_date1,
input#start_date12,
input#start_date2,
input#start_date3,
input#start_date4,
input#flight1StartDate,
input#flight2StartDate,
input#flight3StartDate,
input#flight4StartDate,
input#flight5StartDate,
input#flight6StartDate {
  padding: 11px 11px 9px !important;
  width: 100% !important;
}
input#estimated_date {
  min-width: 250.33% !important;
  max-width: 100% !important;
  min-height: 40px !important;
  font-size: 1em;
  border-radius: 4px;
  border: 1px solid;
  border-color: #ced4da;
  display: block;
  padding-left: 10px !important;
  margin-bottom: 0.5rem !important;
}
input#estimated_date:focus {
  border: 3px solid;
  border-color: #bfdeff;
}

.autocomplete-wrapper .dropdown {
  text-align: left;
  /* border: 1px solid #0f67ff; */
  border-top: none;
  position: absolute;
  max-height: 20em;
  width: 20em;
  margin-top: 0.5em;
  overflow-x: hidden;
  box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3),
    0 4px 8px 3px rgba(60, 64, 67, 0.15);
  z-index: 10112;
  background-color: #fff;
  overflow-x: auto;
}

.autocomplete-wrapper .item {
  display: block;
  cursor: pointer;
  font-size: 14px;
  padding: 15px;
}

.autocomplete-wrapper .item.selected-item {
  background-color: #4caf50;
  color: #fafbfc;
}

.autocomplete-wrapper .item:hover {
  background-color: #4caf50;
  color: #fafbfc;
}
.edit-button {
  top: 22%;
  right: 15%;
  position: absolute;
  z-index: 101;
}

@media (max-width: 991px) {
  .edit-button {
    top: 22.5%;
    right: 42%;
    position: absolute;
    z-index: 101;
  }
  .multi_city {
    min-width: 100% !important;
  }
  .date-range-icon {
    max-width: 45px;
  }
  .tab-content-widget {
    margin: 0px !important;
    width: 100%;
  }
  .booking-option-tab {
    max-width: 50% !important;
  }
  .booking-option-tab {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    height: 42px;
    width: 100%;
    float: left;
  }

  .material-icons,
  .et-icons {
    display: none !important;
  }
  .journey-type-option {
    font-size: 14px !important;
    display: inline !important;
  }

  .input-group-prepend {
    display: inline;
  }
  .trip {
    width: 267px !important;
  }
  .form-label {
    width: auto !important;
  }
  .autocomplete-wrapper input {
    width: 267px !important;
  }

  .SingleDatePickerInput__withBorder {
    width: 120% !important;
  }
  .col {
    min-width: 100% !important;
  }
  .row {
    min-width: 100% !important;
  }
}
.Loading {
  position: relative;
  top: 50% !important;
  z-index: 1000 !important;
  opacity: 1 !important;
}
.Loading-div {
  position: fixed;
  z-index: 1000 !important;
  background-color: rgba(13, 14, 14, 0.74) !important;
  opacity: 1;
  right: 0 !important;
  left: 0 !important;
  height: 100%;
  top: 0;
  width: 100%;
}
.loadingText {
  color: red !important;
}
/* .nav-tabs .nav-link {
  border-radius: 100px !important;
} */
.tab-content-widget {
  margin: 20px;
}
.form-all-widget,
.form-all {
  background-color: #fff;
  position: relative;
  top: -100px;
  /* padding: 0 20px; */
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.19);
  border-radius: 20px;
}
.CalendarDay__selected_span {
  background: #a1e29f !important;
  color: white !important;
  border: 1px solid #a1e29f !important;
}

.CalendarDay__selected {
  background: #60ac5d !important;
  color: white !important;
}

.CalendarDay__selected:hover {
  background: #12f509 !important;
  color: white !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range :hover {
  background: #60ac5d !important;
  color: white !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: #60ac5d !important;
  color: white !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #a1e29f !important;
}
.css-2613qy-menu:hover {
  background-color: #12f509 !important;
}
.valid_date {
  border-radius: 2px !important;
  border: 1px solid #dbdbdb !important;
}
.invalid_date {
  border-radius: 2px !important;
  border: 1px solid red !important;
}
.react-datepicker__input-container input {
  padding: 11px 11px 9px !important;
  min-width: 100% !important;
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  /* min-height: 46px !important; */
}
.react-datepicker-wrapper {
  min-width: 100% !important;
}
.react-datepicker__input-container {
  min-width: 100% !important;
}
.nav-tabs .nav-link {
  margin-top: 0.2em !important;
}
.widget-container .tab-content {
  padding: 0 10px;
}
@media (max-width: 575.98px) {
  .input-group-prepend {
    display: none !important;
  }
  .booking-option-tab.parent.nav-item.nav-link {
    padding-bottom: 55px !important;
  }
  .widget-container {
    padding: 0 0px !important;
    margin: 0 !important;
    border-radius: 0 !important;
  }
  .form-all-widget,
  .form-all {
    border-radius: 0 !important;
  }
  .widget-container .tab-content {
    padding: 0 10px;
  }
  .nav-tabs .nav-link {
    margin-top: 0.2em !important;
    margin-bottom: 20px !important;
  }
}
@media (max-width: 1000px) {
  .input-group-prepend {
    display: none;
  }
  .booking-option-tab.parent.nav-item.nav-link {
    padding-bottom: 0px !important;
    padding-top: 10px;
  }
}

#wrapper-sc .planeImage-sc {
  position: absolute;
  width: 633px;
  height: 318px;
  top: -158px;
  left: -50px;
  z-index: 0;
}
body {
  padding-right: 0 !important;
}
.css-1uccc91-singleValue {
  padding-left: 10px !important;
}
.country-list {
  text-align: left !important;
}
.css-b8ldur-Input input {
  max-width: fit-content !important;
}

.topwidgetmirgin {
  margin-top: 8rem !important;
  /* margin-top: 157px; */
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: initial;
  align-items: stretch;
  width: 100%;
}

.selectOptionscampaign {
  width: 100% !important;
}

.campaignBannnerCaption {
  color: rgb(8, 85, 14) !important;
  /* color: rgb(238, 228, 228) !important; */
  border: none !important;
  bottom: 25px !important;
  position: relative;
  left: 30px;
  cursor: text !important;
  margin-top: -292px !important;
  font-weight: bolder !important;
}
.bannerHeadFont {
  font-size: 34px !important;
  font-weight: bolder !important;
}

.bannerpassengerFont {
  font-size: 25px !important;
  font-weight: bolder !important;
}
.backgroundbanner {
  /* font-size: 15px !important; */
  background-color: #00000042 !important;
  font-weight: bolder !important;
}

.campaignBannnerCaptionMObile {
  font-size: 12px !important;
  font-weight: bolder !important;
  color: rgb(8, 85, 14) !important;
  /* color: rgb(238, 228, 228) !important; */
  border: none !important;
  /* bottom: 25px !important; */
  position: relative;
  left: 6px;
  cursor: text !important;
  margin-top: -112px !important;
}
