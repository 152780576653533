#top-header
{
    background-color: #60ac5d !important;
}
.top-header-container,.header-container
{
    width: 100%;
}
#top-header .nav-link-item
{
    padding: 0 10px !important;
    border:0 !important;
    background-color:  #60ac5d !important;
    color: white !important;
}
#header
{
    background-color: white !important;
}
#header .nav-link-item
{
    padding: 0 10px !important;
    border: 0 !important;
    background-color: white !important;
    color: black !important;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;

}
.logo-img
{
    height: 100px !important;
}


  
  .listgroups{
      cursor: pointer ;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .seeALlList{
      cursor: pointer;
      color: green;
  }
  