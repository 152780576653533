.video-container {
  margin: 0 auto;
  max-width: 58%;
  /* background: url(../../../public/assets/bg-sunset.jpg) top center no-repeat;
  background-size: cover; */
  @media (max-width: 768px) {
    max-width: 85%;
  }

  @media (max-width: 1024px) {
  }
}
