.shoppingCartPosition{    
  position: relative !important;
  right: 101px  !important;
  top: -183px!important;
}
.shoppingCartPositionMobile{    
  position: relative !important;
  right: 61px !important;
  top: -13px!important;
}

.flight-info {
  height: 140px;
  padding: 0px;
  box-shadow: 0 5px 10px #adb5bda6;
  width: 97%;
  margin: auto;
  margin-bottom: 25px;
  cursor: pointer;
  border-radius: 10px;
}
.flight-info-multicity {
  padding: 0px;
  box-shadow: 0 5px 10px #adb5bda6;
  width: 97%;
  margin: auto;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 10px;
}
.flight-duration {
  width: 100%;
  height: 50px;
  background: #dae0e554;
  padding: 15px 12px;
}
.flight-route {
  padding: 0px 18px;
  padding-top: 15px;
  font-size: 0.75rem;
}
p.path-left {
  width: 49%;
  /* background-color: #e3e3e3;
  border: 0.5px solid hsla(0, 0%, 73%, 0.5); */
  position: absolute;
  margin-left: 20.7%;
}
.flight-price-info {
  padding: 5px 18px;
}
.flight-price-info .from-txt {
  font-size: 14px;
}
.flight-price-info .currency,
.flight-price-info .price {
  font-weight: 800;
  font-size: 18px;
  color: #60ac5d;
}
.flight-options-header {
  border-bottom: 2px solid #ddd;
  margin-bottom: 15px;
  background: #60ac5d;
  color: white;
}
.fare-options-cabin {
  padding-bottom: 10px;
  cursor: pointer;
}
.fare-opt-cabin-currency {
  font-size: 12px;
}
.fare-options-cabin.active {
  border-bottom: 3px solid #60ac5d;
}
.fare-option-card {
  box-shadow: 0 5px 10px #adb5bda6;
  width: 96%;
  margin: auto;
  border-radius: 10px;
  cursor: pointer;
}
.fare-option-body {
  background: #e9ecef;
}
.fare-option-card .fare-price,
.seat-left-value,
.tax-price {
  font-size: 12px;
}
.fare-col,
.tax-col {
  border-right: 2px solid white;
}
.fare-option-header {
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 600;
}
.brand-info-icon {
  cursor: pointer;
}
.fare-option-card .brand-name {
  color: #60ac5d;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  font-size: 14px;
  padding: 5px 5px;
  text-align: left;
}
.flight-separtor-dot {
  position: relative;
  top: -6px;
  left: -3px;
  border-top: 1px solid #adb5bd;
  width: 40% !important;
  display: inline-block;
  margin-left: -1px;
}
.fly-duration:hover {
  text-decoration: none;
}
.fly-arrival-time,
.fly-depart-time {
  font-size: 12px;
}
.flight-info-lg-container .flight-info-header {
  padding: 20px 0;
  font-weight: 600;
  background: #fff;
}
.flight-info-lg-container .flight-info-body {
  padding: 20px 0;
  font-size: 12px;
  box-shadow: 0 5px 10px #adb5bd66;
  margin: 10px 0;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
}
.flight-info-lg-container {
  max-width: 1200px;
  width: 65%;
  margin: auto;
  padding: 20px;
  margin-top: 20px;
}
.select-eco-fare-option-btn,
.select-bus-fare-option-btn {
  border: 1px solid #60ac5d !important;
  padding: 0px 10px !important;
  font-size: 14px !important;
  cursor: pointer !important;
  width: 100%;
  border-radius: 5px !important;
}
.fare-option-lg-container {
  border-radius: 10px !important;
  border: 1px solid #ddd;
  background: #fff;
}
.cheap-price-bus,
.cheap-price-eco {
  color: #60ac5d;
  font-size: 16px;
}
.fare-option-lg-container .total-price {
  background: white;
  color: #60ac5d;
  padding: 10px;
  font-size: 18px;
  font-weight: 600;
}
.fare-option-lg-container .fare-price-lg {
  border-right: 2px solid white;
}
.fare-option-lg-container .tax-price-lg {
  border-right: 2px solid white;
}
.submit-fare-option-btn {
  border: 1px solid #60ac5d !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}
.flight-info-lg-container .fare-option-body {
  font-size: 14px;
}
.fare-options-row {
  display: none !important;
}

@media (max-width: 400px) {
  .flight-separtor-dot {
    width: 15% !important;
  }
}
@media (max-width: 860px) {
  .flight-info-lg-container .flight-info-header {
    display: none !important;
  }
}
@media (max-width: 950px) {
  .flight-info-lg-container {
    width: 98% !important;
  }
}
@media (max-width: 1100px) {
  .flight-info-lg-container {
    width: 98% !important;
  }
}
@media (max-width: 1400px) {
  .flight-info-lg-container {
    width: 80%;
  }
}
