.special-offer {
  max-width: 1600px;
  margin: 100px auto;
}
.special-offer-head {
  /* // */
  margin-bottom: 10px;
  font-family: none;
  font-size: 50px;
}
.card-img {
  height: 200px !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.et-btn {
  background-color: #60ac5d !important;
  color: white !important;
}

.card-header {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  background-color: #2d862b;
  /* color: white; */
}


.linebreak {
  white-space: pre-line;
}

.linebreak {
  white-space: pre-wrap;
}
#descriptionMessage {
  white-space: pre-wrap;
}
.descriptionMessage {
  white-space: pre-wrap;
}

 

.btnHolidayslabeldesktop {
  background-color: #00000042 !important;
  font-size: 40px !important;
  font-weight: bolder !important;
  color: rgb(238, 228, 228) !important;
  border: none !important;
  bottom: 25px !important;
  position: relative;
  left: 10px;
  cursor:text !important;
  margin-top: -107px !important;
}
.btnHolidayslabelMobile {
  background-color: #00000042 !important;
  font-size: 15px !important;
  font-weight: bolder !important;
  color: rgb(238, 228, 228) !important;
  border: none !important;
  bottom: 25px !important;
  position: relative;
  /* left: 10px; */
  cursor:text !important;
  margin-top: -32px !important;
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    overflow: hidden !important;
    border-radius: 10rem;
}
.App {
  text-align: none !important;
}
