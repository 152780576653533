.selectreact {
  position: relative;
  box-sizing: border-box;
  width: 158px !important;

  display: flex !important;
  flex-flow: column !important;
}
.contselect {
  right: 0;
}
.dropdownItem {
  cursor: pointer !important;
}

.breadcrumb {
  background-color: white !important;
  padding-left: 2px !important;
}

.center {
  text-align: center !important;
  margin: auto;
  border: 3px solid green;
  padding: 10px;
  vertical-align: middle;
  align-items: center;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0rem !important;
  color: #b62d38;
  content: "";
  margin: -4px;
}

.borderBottom {
  border-bottom: solid 6px !important;
  border-left: 2px solid !important;
  border-right: 2px solid !important;
}
.borderBottomactive {
  border-bottom: solid 6px green !important;
}
