#cookie-dialog-consent {
  background-color: rgba(45, 66, 36, 0.7);
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 5px 0;
  color: #fff;
  z-index: 10000000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  text-align: center;
}
.conset-btn {
  color: white;
  padding: 0.5em 1em;
  text-align: center;
  font-size: 16px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: rgba(45, 66, 36, 0.7);
  color: white;
  border: 2px solid #fff;
  border-radius: 8px;
}

.conset-btn:hover {
  background-color: #fff;
  color: #000;
}
#consent-banner {
  background-color: #08111ad1 !important;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 0px 0;
  color: white;
  z-index: 10000000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.7);
  text-align: center;
}
