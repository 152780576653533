.special-offer {
  max-width: 1600px;
  margin: 100px auto;
}
.special-offer-head {
  margin-bottom: 71px;
  font-family: none;
  font-size: 50px;
}
.card-img {
  height: 200px !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.et-btn {
  background-color: #60ac5d !important;
  color: white !important;
}

.packageCard {
  cursor: pointer !important;
}

.cardLink{
cursor: pointer !important;
}