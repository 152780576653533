.progressbar .first_progressbar:before {
  font-family: FontAwesome;
  content: "1";
}
.progressbar .second_progressbar:before {
  font-family: FontAwesome;
  content: "2";
}.progressbar .third_progressbar:before {
  font-family: FontAwesome;
  content: "3";
}.progressbar .forth_progressbar:before {
  font-family: FontAwesome;
  content: "4";
}.progressbar .fifth_progressbar:before {
  font-family: FontAwesome;
  content: "5";
}.progressbar .sixth_progressbar:before {
  font-family: FontAwesome;
  content: "6";
}.progressbar .seventh_progressbar:before {
  font-family: FontAwesome;
  content: "7";
}
.progressbar .eighth_progressbar:before {
  font-family: FontAwesome;
  content: "8";
}

.progressbar {
  overflow: hidden;
  color: lightgrey;
}

.progressbar .active {
  color: #60ac5d;
}

/* .progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 14%;
  float: left;
  position: relative;
  font-weight: 400;
} */

.progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px 0;
  padding: 2px;
}
.progressbar-text{
  margin-left: -64% !important;
}
.progressbar_lines li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

.progressbar li.active:before,
.progressbar li.active:after {
  background: #60ac5d;
}

/* .progressbarbundle li {
  list-style-type: none;
  font-size: 15px;
  width: 12%;
  float: left;
  position: relative;
  font-weight: 400;
} */
.progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 13%;
  float: left;
  position: relative;
  font-weight: 400;
}
.bread_crumb{
  position: relative;
  left: 2% !important;
}

@media (max-width: 768px) { 
  .bread_crumb{
    display:none; 
  }
  /* .progressbar li:before {
    width: 30px;
    height: 30px;
    line-height: 25px;
    margin: 10px auto 10px auto;

  }
  .progressbar{
    position: relative;
    left: -4%;
    top:1.2em;
  }
  .progressbarhotel li {
    font-size: x-small;
  }
  .progressbarbundle li {
    font-size: x-small;
  } */
 }