.hotel-info-container {
  width: 80%;
  margin: auto;
  margin-bottom: 25px;
  box-shadow: 0 5px 10px #adb5bd66;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  cursor: pointer;
  background: white;
}

.hotel-search-result-header,
.sort-hotel-container,
.hotel-search-form {
  width: 80%;
  margin: auto;
  margin-bottom: 25px;
}
.hotel-img {
  height: 180px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
}
.hotel-price h4 {
  color: #60ac5d;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 2px;
}
.hotel-price-start-from {
  color: #6c757d;
  font-size: 14px;
  padding-right: 5px;
}
.hotel-address span {
  font-size: 14px;
  color: #545b62c7;
}
.hotel-list-container {
  margin-top: 10px;
}
.hotel-detail {
  width: 70%;
  margin: auto;
  margin-top: 10px !important;
}
.hotel-gallery-img {
  height: 400px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
}
.hotel-gallery-container {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.hotel-gallery-container .slick-slider {
  width: 100%;
}
.hotel-room-info {
  box-shadow: 0 5px 10px #dddddd4f;
  border: 1px solid #dddddd4f;
  border-radius: 10px;
  margin-bottom: 20px;
}
.hotel-name h4 {
  font-size: 18px !important;
  font-weight: 600;
  color: black;
}
.hotel-room-img {
  height: 150px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px;
}
.room-price {
  font-size: 18px;
  color: #60ac5d;
  font-weight: 600;
}
span.no-room-guest,
.room-include {
  font-size: 14px;
}
.room-reserve-btn {
  border: 1px solid #b2ceb1 !important;
  border-radius: 10px !important;
  cursor: pointer !important;
}
.room-rate-option-head {
  font-size: 16px;
  width: 90px;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #fff;
  background: #60ac5d;
  color: white;
}
.room-head {
  /* background: #60ac5d;
  color: white;
  text-align: center; */
  font-size: 18px;
  color: black;
  padding: 5px 0;
  font-weight: 600;
  text-align: center;
  border-top: 1px solid #adb5bd;
  border-bottom: 1px solid #adb5bd;
}
.room-option {
  /* background: #60ac5d;
  color: white;
  text-align: center; */    
  /* border-top: 1px solid #adb5bd; */
  border-bottom: 1px solid #adb5bd;
}
.room-name {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.select-room-radio-container {
  /* border: 1px solid #60ac5d; */
  padding: 5px 5px !important;
  border-radius: 11px;
  cursor: pointer !important;
}

.select-room-radio-container label {
  cursor: pointer;
  font-size: 12px;
}
.select-room-radio-container input {
  cursor: pointer;
  transform: scale(1.5);
}
.hotel-facility {
  box-shadow: 0 5px 10px #dddddd4a;
  padding: 10px 20px;
  margin-top: 9px;
  border: 1px solid #ddd;
  border-radius: 26px;
}
#hotel-search-input {
  border-radius: 20px;
  box-shadow: 0 5px 10px #ddd;
}
.hotel-search-btn {
  cursor: pointer !important;
  border-radius: 20px !important;
}
#hotel-search-loader {
  color: #60ac5d !important;
}
.sort-hotel-btn {
  border: 1px solid #60ac5d !important;
  border-radius: 10px !important;
  padding: 2px 20px !important;
}
.sort-hotel-label {
  font-size: 16px;
  margin-bottom: -50px !important;
  margin-top: 12px !important;
}

@media (max-width: 950px) {
  .hotel-detail {
    width: 98% !important;
  }
  .hotel-info-container,
  .sort-hotel-container,
  .hotel-search-result-header,
  .hotel-search-form {
    width: 90% !important;
  }
  .hotel-address span {
    font-size: 12px;
  }
  .hotel-search-result-header h3 {
    font-size: 18px;
  }
}
@media (max-width: 1100px) {
  .hotel-detail {
    width: 98% !important;
  }
  .hotel-info-container,
  .sort-hotel-container,
  .hotel-search-result-header,
  .hotel-search-form {
    width: 80%;
  }
}
@media (max-width: 1400px) {
  .hotel-detail {
    width: 80%;
  }
  .hotel-info-container,
  .sort-hotel-container,
  .hotel-search-result-header,
  .hotel-search-form {
    width: 90%;
  }
}


.hotelRoom{
  /* border-left: green solid 0.2px;
  border-bottom: green solid 0.2px;
  border-width: 2px; */

    
  z-index: 5 !important;
}
.marginULListgroup{
    margin-bottom: 0rem;
  }
  .selectOptions{
      cursor: pointer;
      min-width: 200px !important;
  }
  .main {
    display: block;
    position: relative;
    padding-left: 5px !important;
    cursor: pointer;
    font-size: 18px;
}
.hotelSearcharea{
   text-align:  right !important  ;
  } 
 