#heading {
  text-transform: uppercase;
  color: #60ac5d;
  font-weight: normal;
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}
.form-card {
  text-align: left;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}

.cardTrip {
  z-index: 0;
  border: none;
  position: relative;
}

.fs-title {
  font-size: 25px;
  color: #60ac5d;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

#progressbar {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey;
}

#progressbar .active {
  color: #60ac5d;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar #contact:before {
  font-family: FontAwesome;
  content: "1";
}

#progressbar #travel_detail:before {
  font-family: FontAwesome;
  content: "2";
}

#progressbar #booking:before {
  font-family: FontAwesome;
  content: "3";
}

#progressbar #confirm:before {
  font-family: FontAwesome;
  content: "4";
}

#progressbar li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: #60ac5d;
}

.progress {
  height: 20px;
}
.action-button {
  background: #5cb85c;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #5cb85c;
  margin: 0 5px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
}
.action-button-disabled {
  background: #5cb85c;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #5cb85c;
  margin: 0 5px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
}
.action-button:hover,
.action-button:focus {
  background: #405867;
  border-color: #405867;
}
.previous_button {
  background: transparent;
  color: #99a2a8;
  border-color: #99a2a8;
  border-radius: 5px;
  cursor: pointer;
  min-width: 130px;
  font: 700 14px/40px "Roboto", sans-serif;
  border: 1px solid #5cb85c;
  margin: 0 5px;
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
}
.previous_button:hover,
.previous_button:focus {
  background: #405867;
  border-color: #405867;
  color: #fff;
}
.fit-image {
  width: 100%;
  object-fit: cover;
  color: #60ac5d;
  font-size: 5em;
  text-align: center;
}
.css-1pahdxg-control {
  border-color: #60ac5d !important;
}
.is-invalid {
  border: 1px solid red !important;
}
.travel_age
 { 
  visibility: hidden !important; 
} 

.main { 
  display: block; 
  position: relative; 
  padding-left: 35px !important; 
  cursor: pointer; 
  font-size: 18px; 
} 

/* Creating a custom checkbox 
based on demand */ 
.geekmark { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
  border-radius: 50% ;
} 

/* Specify the background color to be 
shown when hovering over checkbox */ 
.main:hover input ~ .geekmark { 
  background: #405867;
  border-color: #405867
} 

/* Specify the background color to be 
shown when checkbox is active */ 
.main input:active ~ .geekmark { 
  background-color:#5cb85c; 
} 

/* Specify the background color to be 
shown when checkbox is checked */ 
.main input:checked ~ .geekmark { 
  background-color: #5cb85c; 
} 

/* Checkmark to be shown in checkbox */ 
/* It is not be shown when not checked */ 
.geekmark:after { 
  content: ""; 
  position: absolute; 
  display: none; 
} 

/* Display checkmark when checked */ 
.main input:checked ~ .geekmark:after { 
  display: block; 
} 

/* Styling the checkmark using webkit */ 
/* Rotated the rectangle by 45 degree and  
showing only two border to make it look 
like a tickmark */ 
.main .geekmark:after { 
  left: 8px; 
  bottom: 5px; 
  width: 6px; 
  height: 12px; 
  border: solid white; 
  border-width: 0 4px 4px 0; 
  -webkit-transform: rotate(45deg); 
  -ms-transform: rotate(45deg); 
  transform: rotate(45deg); 
} 