.shortDesc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.shorttitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.DateInput_input {
  padding: 1px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.rounded2 {
  /* width: 90%; */
  /* margin: auto  !important;  */
  border-radius: 25px !important;
}
/* filter: (this.state.selectedPackage === item.package.id) ? "opacity(50%)" : "blur(0px)" }} */
