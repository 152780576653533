.collection-item {
  background-color: #fff;
  line-height: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
  /* box-shadow: 0 5px 10px #ccc; */
  margin: 15px 0 !important;
  font-size: 16px;
}

.collection {
  margin-top: 20px !important;
}

.priceDetail {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.closingWidget .close {
  display: block;
  position: absolute;
  right: 0;
  top: -1em;
  font-size: 40px;
  font-family: Tahoma, Arial, sans-serif;
  font-weight: 500;
  color:#fff;
  text-decoration: none;
  z-index: 2000;
}
.shopping-cart {
  width: 100%;
  background-color: whitesmoke;
  box-sizing: border-box;
}
.shopping-cart-header-border {
  border-color: hsl(0deg 0% 80%);
  border-style: solid;
  border-width: 1px 0px 0px 0px;
}
.shopping-cart-header {
  line-height: "3.5rem";
  font-size: "29px";
  font-weight: "500";
  font-family: "Lato, serif";
}
.shopping-cart-left-icon {
  font-family: Oswald, sans-serif;
  color: #2e7d32;
  position: relative;
  top: 0;
  float: left;
}
.shopping-cart-right-icon {
  font-family: Oswald, sans-serif;
  color: #2e7d32;
  position: relative;
  top: 0;
  float: right !important;
  line-height: 2.5rem;
}
.shopping-cart-center-icon {
  font-family: Oswald, sans-serif;
  font-weight: 400;
  line-height: 2.5rem;
  color: #2e7d32;
  justify-content: center;
}
.shopping-cart-title {
  font-family: Oswald, sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
  color: #2e7d32;
}
.shopping-cart-price {
  font-family: Oswald, sans-serif;
  font-weight: 600;
  display: inline-block;
  line-height: 1.5rem;
  color: #333;
}
.shopping-cart-disapled {
  color: #898989 !important;
}
.shopping-cart-date {
  font-weight: 500;
}
.shopping-cart-internal-header {
  background-color: #f2f2f2;
}
.shopping-cart-internal-title {
  color: #4a5767;
  display: flex;
  font-weight: 600;
}
.shopping-cart-airline-logo {
  max-width: 1.333rem !important;
  max-height: 1.333rem !important;
}

.shopping-cart-total-price {
  color: #2e7d32;
  font-size: 1.5rem;
  font-weight: 500;
}
.shopping-cart-label {
  display: block;
  font-size: 1.2rem;
  color: #898989;
}
.shopping-cart-label-tag {
  cursor: pointer;
  text-decoration: underline;
}
.shopping-cart-label-tag:hover {
  text-decoration: none;
  color: #2e7d32;
}
.shopping-cart-active {
  color: #2e7d32;
}
.shopping-cart-tab .nav-link.active {
  background-color: #2e7d32;
  color: white;
  border: 1px solid #a1a1a1;
  border-bottom-color: transparent;
  border-radius: 0.33333rem 0.33333rem 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 15;
}
.shopping-cart-tab a.nav-item.nav-link{
  color: black !important;
}
.plane-icon{
  transform: rotate(322deg);
}
.shopping-cart-header-text{
  font-size: 20px;
}