.side-drawer {
    height: 100%;
    background:whitesmoke;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 400px;
    z-index: 2000;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }
  .side-drawer ul{ 
      height: 100%;
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content:center ;
  }
  .side-drawer li{
      margin: 0.5rem;
  }
  .side-drawer a{
      color: #521751;
      text-decoration: none;
  }
  .side-drawer a:hover,
  .side-drawer a:active{
      color: #fa293f;
  }
  
  .side-drawer.open {
    transform: translateX(0);
  }

  
/* @media (min-width: 768px) { */
    @media(min-width:991px){
    .side-drawer{
        display: none;
    }
}


.sideNavItem:hover{
cursor: pointer;
color: indigo;
font-weight: bold;
background-color: rgba(223, 187, 187, 0.3);
}

.card-header{
    background-color: whitesmoke !important;
}


.mobileMenuHeader{
    background-color:whitesmoke !important;
}
.mobileMenuButton{
    color:green;
    background-color: transparent;
}
.mobileMenuButton:hover{
    color: green;
    text-decoration: none;
    font-weight: bolder;
    border-bottom: 2px solid #60ac5d !important;

}
.mobileMenuButton:focus{
    box-shadow: none;
}
.mobileMenuCard{
    border-bottom: 1px solid #33333342 !important;
    border-top: none !important;
    border-left: none;
    border-right: none;
}
.mobileMenuCloseButton
{    position: absolute;
    top: 0%;
    right: 1%;
    font-size: 20px;
    color: white;
}
@media (max-width: 768px)  {

    .react-autosuggest__input {
        width: 140px !important;
        /* padding: 10px 20px !important; */
        padding: 10px 5px !important; /* 20.5px */
        font-family: Helvetica, sans-serif !important;
        font-weight: 300 !important;
        font-size: 16px !important;
        border: 1px solid #aaa !important;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
  }