/* 

  .react-tel-input .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 35px;
    width: max-content;
    outline: none;
}

@media (max-width: 991px){
.autocomplete-wrapper input {
    width: auto;
}} */

.DateInput {
  margin: 0;
  /* padding: 2; */
  background: #fff;
  position: relative;
  display: inline-block;
  /* width:-webkit-fill-available; */
  vertical-align: middle;
}
.DateInput_input {
  /* width: 100% !important; ; */
  /* padding: 50px*/
  /* padding-left: 3px !important; */
  /* padding-right: 3px !important; */
}
.SingleDatePicker {
  position: relative;
  display: inline-block;
  /* width: 100%; */
}

.formgroupDate {
  margin-bottom: 1rem;
  width: 100% !important;
}
.react-tel-input .form-control {
  width: 100% !important;
}


/* .autocomplete-wrappernationality input {
  border: 1px solid #cecece;
  padding: 7px 15px;
  font-size: 14px;
  min-width: 100% !important;
} */

.inputHeight{ 
  height: calc(1.5em + 0.75rem + 2px) !important;
  min-height: calc(1.5em + 0.75rem + 2px) !important;
}

.inputHeightPassenger{ 
  height: calc(1.5em + 0.75rem + -1px) !important;
  min-height: calc(1.5em + 0.75rem + -1px) !important;
}
.autocomplete-wrapper input {
  height: calc(1.5em + 0.75rem + 2px) !important;
  min-height: calc(1.5em + 0.75rem + 2px) !important;
} 
.react-datepicker__input-container input {
  height: calc(1.5em + 0.75rem + 2px) !important;
  min-height: calc(1.5em + 0.75rem + 2px) !important;
} 
.errorBorder{
  border: solid 1px  !important;
  border-color: #dc3545 !important;
  /* padding-right: calc(1.5em + 0.75rem) !important; */
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e); */
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
.errorBorder2{
  border: solid 1px  !important;
  border-color: #dc3545 !important;
  /* padding-right: calc(1.5em + 0.75rem) !important; */
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e); */
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important; 
} 
.input-group-prepend { 
  width: 45px  !important; 
}