.special-offer {
  max-width: 1600px;
  margin: 100px auto;
}
.special-offer-head {
  margin-bottom: 71px;
  font-family: none;
  font-size: 50px;
}
.card-img {
  /* height: 200px !important; */
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.et-btn {
  background-color: #60ac5d !important;
  color: white !important;
}

.packageCard {
  cursor: pointer !important;
}

.packBannnerCaption {
  color: rgb(255, 255, 255) !important;
  /* color: rgb(238, 228, 228) !important; */
  border: none !important;
  bottom: 25px !important;
  position: relative;
  left: 30px;
  cursor: text !important;
  margin-top: -292px !important;
  font-weight: bolder !important;
}
.packbannerHeadFont {
  font-size: 34px !important;
  font-weight: bolder !important;
}

.packbannerpassengerFont {
  font-size: 25px !important;
  font-weight: bolder !important;
}
.packbackgroundbanner {
  /* font-size: 15px !important; */
  background-color: #00000042 !important;
  font-weight: bolder !important;
}

.packBannnerCaptionMObile {
  font-size: 12px !important;
  font-weight: bolder !important;
  color: rgb(255, 255, 255) !important;
  /* color: rgb(238, 228, 228) !important; */
  border: none !important;
  /* bottom: 25px !important; */
  position: relative;
  left: 6px;
  cursor: text !important;
  margin-top: -112px !important;
}

.img-pkglist { 
  min-height: 140px !important;
}

.card__wrap--outer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

    .card__wrap--outer .card__wrap--inner {
        display: flex;
        flex-direction: row;
        width: 33.33%;
    }

        .card__wrap--outer .card__wrap--inner .card {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
